import React, { useRef } from "react";
import algoliasearch from "algoliasearch/lite";
import PropTypes from "prop-types";
import "./App.css";
import { useState } from "react";

import {
  InstantSearch,
  // HierarchicalMenu,
  RefinementList,
  // SortBy,
  Pagination,
  ClearRefinements,
  Highlight,
  Hits,
  HitsPerPage,
  Panel,
  Configure,
  SearchBox,
  Snippet,
  // ToggleRefinement,
  Stats,
} from "react-instantsearch-dom";
import {
  ClearFiltersMobile,
  NoResults,
  ResultsNumberMobile,
  SaveFiltersMobile,
} from "./widgets";
import Popup from "./widgets/Popup";
import withURLSync from "./URLSync";
// import PJP from "./PJPSvg";
// import { formatNumber } from "./utils";
import "./Theme.css";
import "./App.css";
import "./App.mobile.css";
import "./widgets/Pagination.css";
import pjpLogo from "./assets/pjplogo.jpeg";
// import stockComputer from "./assets/stock_computer.webp";

//brand images import

const searchClient = algoliasearch(
  "WLO9X6NGVM",
  "328abd5d13947e0e9593889bb5004fff"
);

function GetVendorImage(hit) {
  switch (hit["Vendor"].trim()) {
    
    default:
      return pjpLogo;
  }
}

const Hit = ({ hit }) => {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div>
      <div
        className="cursorPointer"
        onClick={() => {
          if (
            hit.Model === "Blodgett 2021 Part Price List" ||
            hit.Model === "Blodgett 2021 Product Price List"
          ) {
            window.location.href = hit.Link;
          } else {
            setButtonPopup(true);
          }
        }}
      >
        <article className="hit">
          <header className="hit-image-container">
            <img
              src={GetVendorImage(hit)}
              className="hit-image"
              alt={hit.Spec}
            />
          </header>

          <Configure
            attributesToSnippet={["Spec:10"]}
            snippetEllipsisText="…"
            removeWordsIfNoResults="allOptional"
          />

          <div className="hit-info-container">
            {/* <p className="hit-category">
        <Highlight attribute="SuperCategory" hit={hit} />
      </p> */}
            <h1>
              <Highlight attribute="Product Name" hit={hit} />
            </h1>
            <p className="hit-description">
              <Snippet attribute="Product Description" hit={hit} />
            </p>
          </div>
        </article>
      </div>
      <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <h3>{hit["Product Name"]}</h3>
        <div className="smallFont">
          <p>
            <b>Brand:</b> {hit["Vendor"]}
          </p>
          <p>
            <b>Product Code:</b> {hit["Product Code"]}
          </p>
          <p>
            <b>Manufacturer Product Number:</b> {hit["ManufacturerProductNumber"]}
          </p>
          <p>
            <b>Description:</b> {hit["Product Description"]}
          </p>
          <p>
            <b>PJP Pack Size:</b> {hit.PJPPackSize}
          </p>
          <p>
            <b>Pack Size:</b> {hit.Packsize}
          </p>
        </div>
      </Popup>
    </div>
  );
};

const App = (props) => {
  const containerRef = useRef(null);
  const headerRef = useRef(null);
  function openFilters() {
    document.body.classList.add("filtering");
    window.scrollTo(0, 0);
    window.addEventListener("keyup", onKeyUp);
    window.addEventListener("click", onClick);
  }

  function closeFilters() {
    document.body.classList.remove("filtering");
    containerRef.current.scrollIntoView();
    window.removeEventListener("keyup", onKeyUp);
    window.removeEventListener("click", onClick);
  }

  function onKeyUp(event) {
    if (event.key !== "Escape") {
      return;
    }

    closeFilters();
  }

  function onClick(event) {
    if (event.target !== headerRef.current) {
      return;
    }

    closeFilters();
  }
  return (
    //for some reason searchbox throws error
    <InstantSearch
      searchClient={searchClient}
      indexName="sandbox_products"
      searchState={props.searchState}
      createURL={props.createURL}
      onSearchStateChange={props.onSearchStateChange}
    >
      <header className="header" ref={headerRef}>
        <img className="headerImage" src={pjpLogo} alt="PJP Product Search" height="200" width="200"/>
        {/* <div className="headerImage">
           
        </div>
        {/* <PJP/> */}
        {/* <p className="header-title">Middleby Product Search</p> */}

        <SearchBox
          translations={{
            placeholder: "Search by name, brand, code, …",
          }}
          submit={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 18 18"
            >
              <g
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.67"
                transform="translate(1 1)"
              >
                <circle cx="7.11" cy="7.11" r="7.11" />
                <path d="M16 16l-3.87-3.87" />
              </g>
            </svg>
          }
        />
      </header>

      <main className="container" ref={containerRef}>
        <div className="container-wrapper">
          <section className="container-filters" onKeyUp={onKeyUp}>
            <div className="container-header">
              <h2>Filters</h2>
              <div className="clear-filters" data-layout="desktop">
                <ClearRefinements
                  translations={{
                    reset: (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                        >
                          <g fill="none" fillRule="evenodd" opacity=".4">
                            <path d="M0 0h11v11H0z" />
                            <path
                              fill="#000"
                              fillRule="nonzero"
                              d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                            />
                          </g>
                        </svg>
                        Clear filters
                      </>
                    ),
                  }}
                />
              </div>
              <div className="clear-filters" data-layout="mobile">
                <ResultsNumberMobile />
              </div>
            </div>

            <div className="container-body">
              <Panel header="Brand">
                <RefinementList
                  attribute="Vendor"
                  showMore={true}
                  searchable={true}
                  translations={{
                    placeholder: "Search for brands...",
                  }}
                />
              </Panel>
              <Panel header="PJP Pack Size">
                <RefinementList
                  attribute="PJPPackSize"
                  showMore={true}
                  searchable={true}
                  translations={{
                    placeholder: "Search for pack sizes...",
                  }}
                />
              </Panel>
            </div>
          </section>

          <footer className="container-filters-footer" data-layout="mobile">
            <div className="container-filters-footer-button-wrapper">
              <ClearFiltersMobile containerRef={containerRef} />
            </div>

            <div className="container-filters-footer-button-wrapper">
              <SaveFiltersMobile onClick={closeFilters} />
            </div>
          </footer>
        </div>

        <section className="container-results">
          <header className="container-header container-options">
            {/* <SortBy
              className="container-option"
              defaultRefinement="prod_NAME"
              items={[
                {
                  label: "Sort by featured",
                  value: "prod_NAME",
                },
              ]}
            /> */}
            <Stats className="container-option" />
            <HitsPerPage
              className="container-option"
              items={[
                {
                  label: "16 hits per page",
                  value: 16,
                },
                {
                  label: "32 hits per page",
                  value: 32,
                },
                {
                  label: "64 hits per page",
                  value: 64,
                },
              ]}
              defaultRefinement={16}
            />
          </header>
          <Hits hitComponent={Hit} />
          <NoResults />

          <footer className="container-footer">
            <Pagination
              padding={2}
              showFirst={false}
              showLast={false}
              translations={{
                previous: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.143"
                    >
                      <path d="M9 5H1M5 9L1 5l4-4" />
                    </g>
                  </svg>
                ),
                next: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.143"
                    >
                      <path d="M1 5h8M5 9l4-4-4-4" />
                    </g>
                  </svg>
                ),
              }}
            />
          </footer>
        </section>
      </main>
      <aside data-layout="mobile">
        <button
          className="filters-button"
          data-action="open-overlay"
          onClick={openFilters}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14">
            <path
              d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
              stroke="#fff"
              strokeWidth="1.29"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Filters
        </button>
      </aside>
    </InstantSearch>
  );
};

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

// export default App;

export default withURLSync(App);
